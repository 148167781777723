import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
   } from '@angular/common/http';
   import { Observable, throwError } from 'rxjs';
   import { retry, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
   
   export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request)
        .pipe(
          retry(1),
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error              
              console.log(`Error Code: ${error.status}\nMessage: ${error.message}`);
             
              let errorCode = `${error.status}`;

              // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
               if(errorCode == '401'){
                errorMessage =`Authorization Expired. Please Login Again`;                
               }else if(errorCode == '400'){
                errorMessage =` Bad Request .`;                
               }else{
                errorMessage = `Error Code: ${error.status}\nMessage: Please contact to adminstrator`;
               }
            }
           // window.alert(errorMessage);
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: errorMessage,
              showConfirmButton: false,
            timer: 1000
            })
            return throwError(errorMessage);
          })
        )
    }
   }