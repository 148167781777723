import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { Warehouse } from '../warehouse-management/brandwarehouse/warehouse';
import { Storeinfo } from '../store-management/storeinfo/storeinfo';
import { StorePriceModel } from '../brand-modal/store-price-model';
import { Storechargediscount } from '../brand-modal/storechargediscount';
import { Storeuser } from '../store-management/storeuser/storeuser';
import { Storereportmodel } from '../brand-modal/storereportmodel';
import { StoreNotificationTemplate } from '../brand-modal/store-notification-template';
import { StoreWarehouse } from '../brand-modal/store-ware-house';
import { Brandinfo } from '../brand-management/brandinfo/brandinfo';
import { BrandPriceList } from '../brand-modal/brand-price-list';
import { Chargediscount } from '../brand-modal/chargediscount';
import { BrandNotificationTemplate } from '../brand-modal/brand-notification-template';
import { Brandreportmodel } from '../brand-modal/brandreportmodel';
import { Requestdate } from '../../dashboards/dashboard1/requestdate';
import { StoreQRCodeConfig } from '../brand-modal/storeQRCodeConfig';
import { Branduser } from '../brand-management/branduser/branduser';
import { BrandExpense } from "../brand-management/expense/brandexpense";
import { TermCondition } from "../brand-management/term-condition/termcondition";
import { StoreTermCondition } from "../store-management/store-term-condition/storetermcondition";
import { PaginationReq } from '../brand-modal/paginationReq';


@Injectable({
  providedIn: 'root'
})
export class BrandAdminService {
  baseUrl: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.baseUrl = environment.apiEndpoint;
  }


  getBrandInfo()  {
    console.log("======= this.authService.getBrandId() ==========" + this.authService.getBrandId());
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() , {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandLogo2() {
    console.log("======= this.authService.getBrandId() ==========" + this.authService.getBrandId());
  return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() +'/brand-logo', {
    headers: this.getHeaderWithAuth(),
    observe: 'response',
    responseType: 'json'
  }) .pipe(map((data =>
    data)));
  }



  getBrandLogo(): Observable<any> {
    //console.log(path);
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() +'/brand-logo', {
      headers: new HttpHeaders({
        observe: 'response' as 'response',
        Authorization: this.authService.getToken()
      })
    });
  }

  getBrandStoreLogo(storeId : number): Observable<any> {
    //console.log(path);
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() +'/store/'+ storeId +'/store-logo', {
      headers: new HttpHeaders({
        observe: 'response' as 'response',
        Authorization: this.authService.getToken()
      })
    });
  }


  getAccountInfo()  {
    return this.httpClient.get(this.baseUrl + 'home/loginsuccess', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getBrandPriceCode()  {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/price-code', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandStore() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandStoreCustomer() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/customer', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandStoreCustomerCount(paginationReq : PaginationReq) {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/brand-store-customer-count', paginationReq,{
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

   getBrandStoreCustomerNew(paginationReq : PaginationReq) {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/brand-store-customer',paginationReq, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandPackage() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/brand-package', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getBrandGarmentsListByPriceId(priceId : number)  {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/price-code/'+ priceId, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }






  onUploadPriceListForm2(fileData: any)  {
    console.log(fileData);
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/add-price-list', fileData, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  onUploadPriceListForm(fileData: any)  {
    console.log("================= fileData ============== ");
    console.log(fileData);
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/upload-price-list', fileData, {
     headers: this.getHeaderWithAuthForFile()
    }) .pipe(map((data =>
      data)));
  }


  addNewBrandPackage(brandPackage) {
    brandPackage.brandId = +this.authService.getBrandId();
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/brand-package', brandPackage, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  editNewBrandPackage(brandPackage) {
   // brandPackage.brandId = +this.authService.getBrandId();
   console.log("=================== brandPackage ==================");
   console.log(brandPackage);
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/brand-package', brandPackage, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }



  getBrandStorePrice()  {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/store-price', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandStorePriceList(storeId : number)  {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeId+'/price', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  brandStorePackageList(storeId : number) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeId + '/package' , {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  brandStorePriceList(storeId : number) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeId + '/price', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  brandStoreCharge(storeId : number) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeId + '/charge', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  brandStoreDiscount(storeId : number)  {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeId + '/discount', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  brandStoreUser(storeId : number)  {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeId + '/user', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getBrandStoreReport(storeId : number) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeId + '/report', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  editBrandStoreReport(storeReport : Storereportmodel) {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeReport.storeId + '/report', storeReport, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  addBrandStoreReport(storeReport : Storereportmodel) {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeReport.storeId + '/report', storeReport, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getBrandStoreNotificationTemplate(storeId : number) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeId + '/notification-template', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  addBrandStoreNotificationTemplate(stoerNotificationTempalte : StoreNotificationTemplate) {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ stoerNotificationTempalte.storeId + '/notification-template',stoerNotificationTempalte, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  updateBrandStoreNotificationTemplate(stoerNotificationTempalte : StoreNotificationTemplate) {
  console.log(stoerNotificationTempalte);
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ stoerNotificationTempalte.storeId + '/notification-template', stoerNotificationTempalte, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandStoreWareHouse(storeId : number) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeId + '/ware-house', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  addBrandStoreWareHouse(storeWareHouse: StoreWarehouse) {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeWareHouse.storeDTO.id + '/ware-house', storeWareHouse, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  updateBrandStoreWareHouse(storeWareHouse: StoreWarehouse) {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+ storeWareHouse.storeDTO.id + '/ware-house', storeWareHouse, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }



  getBrandWareHouse() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/ware-house', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  addBrandWareHouse(warehouse : Warehouse) {
    warehouse.brandDTO.id = +this.authService.getBrandId();
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/ware-house', warehouse, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  editBrandWareHouse(warehouse : Warehouse) {
    warehouse.brandDTO.id = +this.authService.getBrandId();
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/ware-house', warehouse, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  activateBrandWareHouse(warehouseId : number) {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/ware-house/' + warehouseId + '/activate', {}, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  editBrandStore(storeInfo : Storeinfo) {

    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/' + storeInfo.id , storeInfo, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  editBrandInfo(brandInfo : Brandinfo) {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId()  , brandInfo, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  saveBrandLogo(formData: any)  {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/upload-brand-logo' , formData, {
     headers: this.getHeaderWithAuthForFile()
    }) .pipe(map((data =>
      data)));
  }


  saveBrandStoreLogo(storeId : number, formData: any)  {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/' + storeId + '/upload-store-logo' , formData, {
     headers: this.getHeaderWithAuthForFile()
    }) .pipe(map((data =>
      data)));
  }


  editBrandStorePriceList(storePriceModel : StorePriceModel)  {
     console.log(storePriceModel);
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/store-price'  , storePriceModel, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  addBrandStorePriceList(storePriceModel : StorePriceModel)  {
    console.log(storePriceModel);
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/store-price'  , storePriceModel, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  // assignPriceListToStore(storePriceModel){
  //   console.log(storePriceModel);
  //   return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/store-price', storePriceModel, {
  //     headers: new HttpHeaders({
  //       Authorization: this.authService.getToken()
  //     })
  //   }).pipe(map((data =>
  //     data)));
  // }

  editBrandStoreChargeDiscount(storechargediscount : Storechargediscount)  {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/' +storechargediscount.storeId +'/charge-discount'  , storechargediscount, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  editBrandStoreUser(storeuser : Storeuser)  {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/' +storeuser.storeId +'/user'  , storeuser, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  editBrandStoreUserInfo(storeuser : Storeuser)  {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/' +storeuser.storeId +'/user-info'  , storeuser, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  addBrandStoreChargeDiscount(storechargediscount : Storechargediscount)  {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/' +storechargediscount.storeId +'/charge-discount'  , storechargediscount, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getStoreQRCodeConfig(storeId : number) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/' + storeId + '/qrcode-config', {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }

  updateStoreQRCodeConfig(storeId :number,  storeQRCodeConfig : StoreQRCodeConfig)  {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/' +storeId +'/qrcode-config'  , storeQRCodeConfig, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  blockBrandWareHouse(warehouseId : number)  {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/ware-house/'+ warehouseId + '/block', {},{
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  addNewBrandPrice(brandPrice : BrandPriceList)  {
    brandPrice.brandId = +this.authService.getBrandId();
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/brand-price', brandPrice, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  editBrandPrice(brandPrice : BrandPriceList)  {
    brandPrice.brandId = +this.authService.getBrandId();
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/brand-price', brandPrice, {
     headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  garmentList() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/'+ this.authService.getBrandId() + '/garment', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  serviceList() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/'+ this.authService.getBrandId() + '/garment-service', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getPackageTypeList(){
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/'+ this.authService.getBrandId() + '/package-type', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getServiceUnitList(){
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/'+ this.authService.getBrandId() + '/service-unit', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  categoryList() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/'+ this.authService.getBrandId() +'/garment-category', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  garmentCategoryList(){
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/'+ this.authService.getBrandId() +'/garment-category-list', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  garmentCategoryServiceList() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/'+ this.authService.getBrandId() + '/garment-category-service', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  saveGarmentPriceList(garmentServicePrice) {
    console.log("================= saveGarmentPriceList ============== ");
    console.log(garmentServicePrice);
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/garment-category-service-price', garmentServicePrice, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getBrandOrderDiscountList() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/discount', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandOrderChargeList() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/charges', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  editBrandChargeDiscount(chargeDiscount : Chargediscount) {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/charge-discount', chargeDiscount, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  addBrandChargeDiscount(chargeDiscount : Chargediscount) {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/charge-discount', chargeDiscount, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandNotificationTemplate() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/notification/template', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  editBrandNotificationTemplate(editNotificationTemplate : BrandNotificationTemplate) {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/notification/template', editNotificationTemplate, {
      headers: this.getHeaderWithAuth()
    })  .pipe(map((data =>
      data)));
  }




  getTemplateType() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/notification/template-type', {
      headers: this.getHeaderWithAuth()
    })  .pipe(map((data =>
      data)));
  }


  getBrandUser() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/user', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  editBrandUser(brandUser: Branduser) {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/user', brandUser, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getBrandSmsAPI() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/sms-api', {
      headers: this.getHeaderWithAuth()
    })  .pipe(map((data =>
      data)));
  }



  editBrandReport(editBrandReport : Brandreportmodel) {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/report', editBrandReport,{
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getStoreOrderChargeList(storeId : number ) {
    return this.httpClient.get(this.baseUrl + 'auth/store/charges/' + storeId  , {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getStoreOrderDiscountList(storeId : number) {
    return this.httpClient.get(this.baseUrl + 'auth/store/discount/' + storeId , {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }




  // DashBoard api call

  getBrandOrderList(startDate: string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/order/'+ startDate+'/' + endDate , {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandOrderSourceStatusList(startDate: string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/order-source-status/'+ startDate+'/' + endDate , {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }

  getBrandStoreOrderList(storeId: number, startDate: string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/order/'+ storeId +'/'+ startDate+'/' + endDate , {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }




  getBrandTotalOrder(startDate : string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/order-count/'  + startDate + '/' + endDate, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandTotalSale(startDate : string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/sale-count/'  + startDate + '/' + endDate, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }



  getBrandTotalCollection(startDate : string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/collection-count/'  + startDate + '/' + endDate, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }



  getBrandTotalNewCustomer(startDate : string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/new-customer-count/'  + startDate + '/' + endDate, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getSalesRatio(startDate : string, endDate: string)  {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/sales-ratio/'  + startDate + '/' + endDate, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getBrandStoreSale(dateList : Requestdate) {
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/store-sales/',  dateList, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  getBrandCustomerRatio(startDate : string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/customer-ratio/'  + startDate + '/' + endDate, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }



  getBrandWorkLoadStatus(startDate : string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/brand-work-load/'  + startDate + '/' + endDate, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getBrandDayCollection(startDate : string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/brand-day-collection/'  + startDate + '/' + endDate, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  // Bussiness Report api call


  getBrandReport() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/report', {
      headers: this.getHeaderWithAuth()
    })   .pipe(map((data =>
      data)));
  }

  getBrandReportById(reportId: number, startDate : string, endDate: string) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/report/' + reportId + '/' + startDate + '/' + endDate, {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getBrandReportByStatus(reportRequestData : any) {
    reportRequestData.brandId = this.authService.getBrandId();
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/report-by-status' , reportRequestData , {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  generateBrandReportByStatus(reportRequestData : any) {
    reportRequestData.brandId = this.authService.getBrandId();
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/generate-report-by-status' , reportRequestData , {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  getPaymentMode() {
    return this.httpClient.get(this.baseUrl + 'auth/store/payment-mode' , {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }


  generateBrandReport(reportId, startDate, endDate) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/report/' + reportId + '/' + startDate + '/' + endDate +  '/generate', {
      headers: this.getHeaderWithAuth()
    }) .pipe(map((data =>
      data)));
  }

  downloadBrandReport(reportId) {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/report/'  + '/download/' + reportId, {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }

  getHeaderWithAuth(){
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', this.authService.getToken());
    return headers;
  }


  getHeaderWithAuthForFile(){
    console.log("=========== multipart ========");
    let headers = new HttpHeaders();
   // headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('Authorization', this.authService.getToken());
    return headers;
  }

  getGeneratedDownloadReport() {
     return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/report/download-report' , {
      headers: this.getHeaderWithAuth()
    })
    .pipe(map((data =>
      data)));
  }

  downloadReportNow(reportId: string): Observable<HttpResponse<Blob>> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/vnd.ms-excel');
    headers = headers.append('Authorization', this.authService.getToken());
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/dashboard/report/download-report/' + reportId +'/download', {
      headers: headers,
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadPriceCodeList(): Observable<HttpResponse<Blob>> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/vnd.ms-excel');
    headers = headers.append('Authorization', this.authService.getToken());
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/price-code-list' , {
      headers: headers,
      observe: 'response',
      responseType: 'blob'
    }) .pipe(map((data =>
      data)));
  }

  getExpenseType() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/expense-type', {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }


  getBrandExpense() {
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/brand-expense', {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }

  addBrandExpense(brandExpense: BrandExpense) {
    brandExpense.brand = +this.authService.getBrandId();
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/brand-expense', brandExpense, {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }


  editBrandExpense(brandExpense: BrandExpense) {
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/brand-expense', brandExpense, {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }


  getStoreExpense(storeId : number){
    return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store/'+storeId+'/expense', {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }

  addStoreExpense(storeExpense : any){
    return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store-expense', storeExpense, {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }

  editStoreExpense(storeExpense : any){
    return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/store-expense', storeExpense, {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
  }


  getStoreTermCondition(storeId : number){
    return this.httpClient.get(this.baseUrl + 'auth/store/' + storeId + '/term-condition', {
      headers: this.getHeaderWithAuth()
    }).pipe(map((data =>
      data)));
    }

    addStoreTermCondition(storeTermCondition : StoreTermCondition){
      return this.httpClient.post(this.baseUrl + 'auth/store/'  + storeTermCondition.store + '/term-condition', storeTermCondition, {
        headers: this.getHeaderWithAuth()
      }).pipe(map((data =>
        data)));
      }

      updateStoreTermCondition(storeTermCondition : StoreTermCondition){
        return this.httpClient.put(this.baseUrl + 'auth/store/'  + storeTermCondition.store + '/term-condition', storeTermCondition, {
          headers: this.getHeaderWithAuth()
        }).pipe(map((data =>
          data)));
        }


        getBrandTermCondition(){
          return this.httpClient.get(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/term-condition', {
            headers: this.getHeaderWithAuth()
          }).pipe(map((data =>
            data)));
        }

        addBrandTermCondition(termCondition : TermCondition){
          return this.httpClient.post(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/term-condition', termCondition, {
            headers: this.getHeaderWithAuth()
          }).pipe(map((data =>
            data)));
        }

        editBrandTermCondition(termCondition : TermCondition){
          return this.httpClient.put(this.baseUrl + 'auth/admin/brand/' + this.authService.getBrandId() + '/term-condition', termCondition, {
            headers: this.getHeaderWithAuth()
          }).pipe(map((data =>
            data)));
        }
}
