export const environment = {
  production: false,

//  apiEndpoint: 'http://localhost:12002/api/',
//  apiEndpointBase: 'http://localhost:12002/',

apiEndpointPrintSeriveBase: 'http://localhost:12001/',

  apiEndpoint: 'https://api.elaundry.co.in/oit-elaundry/api/',
   apiEndpointBase: 'https://api.elaundry.co.in/oit-elaundry/'


};

